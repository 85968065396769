<template>
	<div class="user-page__wrapper">
		<div class="user-page">
			<UserMenu />
			<div class="user-page__content my-virtual-bag">
				<h2>{{ title }}</h2>
				<VueMarkdown v-if="description" :source="description" :prerender="prerender" />

				<div class="tabs dcp__tabs">
					<ul>
						<li :class="{ 'is-active': activeFilter === 'all' }"><a @click.prevent="changeFilter('all')">All</a></li>
						<li :class="{ 'is-active': activeFilter === 'my' }">
							<a @click.prevent="changeFilter('my')">My Digital Congress Bag</a>
							<span  v-if="myCongressItemsCount > 0" class="dcp__tabs-count">{{ myCongressItemsCount }}</span>
						</li>
					</ul>
				</div>

				<div v-if="allCongressItems.length > 0" class="virtual-bag-items">
					<div v-for="item in selectedItems" :key="item.id" class="virtual-bag-item">
						<div class="virtual-bag-item__thumb" @click.prevent="openItem(item.id)"><img :src="item.thumbnail | imageUrl" /></div>
						<div class="virtual-bag-item__title" @click.prevent="openItem(item.id)">
							<h2>{{ item.title }}</h2>
							<h3>{{ item.subtitle }}</h3>
						</div>
						<div class="virtual-bag-item__action">
							<a class="virtual-bag-item__like" @click.prevent="likeItem(item)">
								<inline-svg v-show="!item.liked" :src="require('../assets/heart.svg')" width="25"></inline-svg>
								<inline-svg v-show="item.liked" :src="require('../assets/heart-filled.svg')" width="25"></inline-svg>
							</a>
							<a class="virtual-bag-item__detail" @click.prevent="openItem(item.id)">Open</a>
						</div>
					</div>
				</div>

				<VueMarkdown v-if="selectedItems.length === 0 && emptyWarning" :source="emptyWarning" :prerender="prerender" />
			</div>
		</div>
	</div>
</template>

<script>
	import $http from '../utils/http';
	import meta from '../utils/meta';
	import VirtualBagModal from '../components/VirtualBagModal';
	import UserMenu from '../components/UserMenu';
	import VueMarkdown from 'vue-markdown-v2';
	import { prerender } from '../utils/markdown';

	export default {
		name: 'VirtualBag',
		components: { UserMenu, VueMarkdown },
		data() {
			return {
				title: '',
				description: '',
				background: '',
				allCongressItems: [],
				emptyWarning: '',
				activeFilter: 'my',
			};
		},
		computed: {
			backgroundUrl() {
				return this.background && this.background.url ? `url('${this.$options.filters.imageUrl(this.background)}')` : null;
			},
			selectedItems() {
				return this.activeFilter === 'all' ? this.allCongressItems : this.myCongressItems;
			},
			myCongressItems() {
				return this.allCongressItems.filter((item) => item.liked);
			},
			myCongressItemsCount() {
				return this.myCongressItems.length;
			},
		},
		beforeRouteEnter(to, from, next) {
			$http.all([$http.get('page-my-virtual-bag'), $http.get(`/users-congress-bag`)]).then(
				$http.spread((page, data) => {
					next(vm => {
						vm.setPage(page);
						vm.setData(data);
					});
				})
			);
		},
		beforeRouteUpdate(to, from, next) {
			$http.all([$http.get('page-my-virtual-bag'), $http.get(`/users-congress-bag`)]).then(
				$http.spread((page, data) => {
					this.setPage(page);
					this.setData(data);
					next();
				})
			);
		},
		methods: {
			setPage({ data }) {
				if (data.title) {
					this.title = data.title;
				}
				meta.setTitle(this.title);
				this.description = data.description;
				this.emptyWarning = data.empty_bag_warning;
			},
			changeFilter(filter) {
				this.activeFilter = filter;
			},
			setData(data) {
				this.allCongressItems = data.data.sort((a, b) => (b.order !== null) - (a.order !== null) || a.order - b.order);
			},
			openItem(item) {
				let data = this.selectedItems.find(e => e.id === item);
				this.$gtm.dataLayer().push({
					event: 'gaEvent',
					eCategory: 'No Booth Event',
					eAction: 'Digital Congress Bag - Card',
					eLabel: data.title ? data.title : null,
					Exhibitor: data.gtm_exhibitor ? data.gtm_exhibitor : '(not set)',
					Booth: '(not set)',
				});
				this.$modal.show(
					VirtualBagModal,
					{
						current: item,
						items: this.selectedItems,
					},
					{
						classes: 'virtual-bag-modal',
						width: '90%',
						maxWidth: 820,
						height: 'auto',
						adaptive: true,
					},
					{
						'before-close': ({ params }) => {
							if (params) {
								this.openItem(params);
							}
						},
					}
				);
			},
			likeItem(item) {
				item.liked = !item.liked;
				if (item.liked === true) {
					$http.post(`/congress-items/${item.id}/likes`).then(
						() => {},
						() => {
							item.liked = !item.liked;
						}
					);
				} else if (item.liked === false) {
					$http.delete(`/congress-items/${item.id}/likes`).then(
						() => {},
						() => {
							item.liked = !item.liked;
						}
					);
				}
			},
			prerender: prerender,
		},
	};
</script>

<style scoped></style>
